import { combineReducers } from 'redux';

// Config Dynamic
import appState from './app/reducer';

import connection from './connection/reducer';
import user from './user/reducer';

// Reducer from Containers
import tokensReducer from './tokens/reducer';
import web3ReactReducer from './web3React/reducer';
import loadingReducer from './loading/reducer';
import networkReducer from './network/reducer';

// Config Dynamic
import config from './config/reducer';

import { default as formBridgeReducer } from '../containers/FormBridge/state/reducer';

const reducers = combineReducers({
  web3ReactReducer,
  formBridgeReducer,
  user,
  connection,
  tokensReducer,
  loadingReducer,
  networkReducer,
  config,
  appState,
});

export default reducers;
