import { createAsyncThunk } from '@reduxjs/toolkit';

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { INetwork, NetworkReducerState } from './types';
import { getNetworkListAPI } from '@/services/networks';
import { RootState } from '..';

export const PREDIX = 'NETWORKS';

const setNetworks: CaseReducer<NetworkReducerState, PayloadAction<INetwork[]>> = (
  state,
  action,
) => {
  state.networkList = action.payload;
};

const setNetworkListUsing: CaseReducer<
  NetworkReducerState,
  PayloadAction<INetwork[]>
> = (state, action) => {
  state.networkListByUsing = action.payload;
};

const setCurrentL2Network: CaseReducer<
  NetworkReducerState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.currentL2Network = action.payload;
};

const setNetworkNameAvailableList: CaseReducer<
  NetworkReducerState,
  PayloadAction<string[]>
> = (state, action) => {
  state.networkNameAvailableList = action.payload;
};

const fetchNetworks = createAsyncThunk(
  `${PREDIX}/fetchNetworks`,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const dataList = await getNetworkListAPI();
    return dataList;
  },
);

const actionCreators = {
  setNetworks,
  setCurrentL2Network,
  setNetworkNameAvailableList,
  setNetworkListUsing,
};

// Export Pure Actions
export { actionCreators };

// Export Async Actions
export { fetchNetworks };
