import { MetaConfig, ThemeConfig, WebsiteConfig } from './type';

export const MetaConfigInit: MetaConfig = {
  favIconUrl: `https://cdn.trustless.domains/icons/ic-bridge.svg`,
  tabTitle: 'Trustless Bridge',
  tabDesc:
    'A two-way bridge that enables seamless transfer of assets between Bitcoin and Trustless Computer.',
  thumbImgUrl: `https://cdn.trustless.domains/images/metadata-Trustless-brige.jpg`,
};

export const ThemeConfigInit: ThemeConfig = {
  mode: 'dark',
  primaryColor: '#3772ff',
  secondaryColor: '#494b51',
};

export const WebsiteConfigInit: WebsiteConfig = {
  metaConfig: MetaConfigInit,
  themeConfig: ThemeConfigInit,
};
