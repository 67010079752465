import {
  GenerateDepositData,
  GenerateDepositPayload,
} from '@/interfaces/api/deposit';
import { generateDepositDataAPI } from '@/services/deposit';
import { estimateWithdrawFeeAPI } from '@/services/withdraw';
import { Token } from '@/state/tokens/types';
import { CaseReducer, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormBridgeState } from './reducer';
import { FormType } from './types';

import { INetwork, NetworkName } from '@/state/network/types';
import { EstimateWithdrawFeePayload } from '@/interfaces/api/withdraw';

export const PREDIX = 'FormBridge';

const setLoading: CaseReducer<FormBridgeState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.isLoading = action.payload;
};

const setFromNetwork: CaseReducer<
  FormBridgeState,
  PayloadAction<NetworkName | undefined>
> = (state, action) => {
  const networkStr = action.payload;

  //Reset
  state.fromNetworkSelected = networkStr;
  state.toNetworkSelected = undefined;
  state.fromTokenSelected = undefined;
  state.fromBalance = '0';
  state.error = undefined;
};

const setFromNetworkObj: CaseReducer<
  FormBridgeState,
  PayloadAction<INetwork | undefined>
> = (state, action) => {
  state.fromNetworkObjSelected = action.payload;
};

const setFromTokenSelected: CaseReducer<
  FormBridgeState,
  PayloadAction<Token | undefined>
> = (state, action) => {
  state.fromTokenSelected = action.payload;

  //Reset
  state.toNetworkSelected = undefined;
  state.toTokenSelected = undefined;
  state.fromBalance = '0';
  state.error = undefined;
};

const setFromInputAmount: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.fromInputAmount = action.payload;
  state.error = undefined;
};

const setFromBalance: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.fromBalance = action.payload;
};

const setMaxErrorMessage: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.error = action.payload;
};

const setAllowanceAmount: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.allowanceAmount = action.payload;
};

const setToBalance: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.toBalance = action.payload;
  state.error = undefined;
};

const setToNetwork: CaseReducer<FormBridgeState, PayloadAction<NetworkName>> = (
  state,
  action,
) => {
  state.toNetworkSelected = action.payload;
};

const setToNetworkObj: CaseReducer<
  FormBridgeState,
  PayloadAction<INetwork | undefined>
> = (state, action) => {
  state.toNetworkObjSelected = action.payload;
};

const setToInputAmount: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.toInputAmount = action.payload;
  state.error = undefined;
};

const setGenerateDepositData: CaseReducer<
  FormBridgeState,
  PayloadAction<GenerateDepositData | undefined>
> = (state, action) => {
  state.generateDepositData = action.payload;
  state.error = undefined;
};

const swapAction: CaseReducer<FormBridgeState, PayloadAction> = (state) => {
  const {
    fromNetworkSelected: oldFromNetworkSelected,
    toNetworkSelected: oldToNetworkSelected,
  } = state;

  if (oldToNetworkSelected) {
    state.fromNetworkSelected = oldToNetworkSelected;
  }

  //Reset
  state.toNetworkSelected = undefined;
  state.fromTokenSelected = undefined;
  state.fromBalance = '0';
  state.error = undefined;
};

const setFormType: CaseReducer<FormBridgeState, PayloadAction<FormType>> = (
  state,
  action,
) => {
  state.formType = action.payload;
};

const setFormInstance: CaseReducer<FormBridgeState, PayloadAction<any>> = (
  state,
  action,
) => {
  state.formInstance = action.payload;
  state.error = undefined;
};

const clearGenerateDepositData: CaseReducer<FormBridgeState, PayloadAction> = (
  state,
) => {
  state.generateDepositData = undefined;
  state.generateDepositDataLoading = false;
  state.generateDepositError = undefined;
};

const clearEstimateWithdrawData: CaseReducer<FormBridgeState, PayloadAction> = (
  state,
) => {
  state.estimateWithdrawData = undefined;
  state.estimateWithdrawLoading = false;
  state.estimateWithdrawError = undefined;
};

const setFeeBurnNativeToken: CaseReducer<FormBridgeState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.feeBurnNativeToken = action.payload;
};

const actionCreators = {
  setLoading,

  setFromNetwork,
  setFromNetworkObj,

  setFromTokenSelected,
  setFromInputAmount,
  setFromBalance,

  setToNetwork,
  setToNetworkObj,
  setToInputAmount,
  setToBalance,
  setMaxErrorMessage,

  setGenerateDepositData,

  swapAction,
  setFormType,
  setAllowanceAmount,
  setFormInstance,
  clearGenerateDepositData,
  clearEstimateWithdrawData,

  //Burn Natiev Token
  setFeeBurnNativeToken,
};

const fetchGenerateDepositData = createAsyncThunk(
  `${PREDIX}/fetchGenerateDepositData`,
  async (payload: GenerateDepositPayload) => {
    try {
      const data = await generateDepositDataAPI(payload);
      return data;
    } catch (error: any) {
      throw error;
    }
  },
);

const fetchEstimateWithdrawData = createAsyncThunk(
  `${PREDIX}/fetchEstimateWithdrawData`,
  async (payload: EstimateWithdrawFeePayload) => {
    try {
      const data = await estimateWithdrawFeeAPI(payload);
      return data;
    } catch (error: any) {
      throw error;
    }
  },
);

// Export Pure Actions
export { actionCreators };
// Export Async Actions
export { fetchGenerateDepositData, fetchEstimateWithdrawData };
