import 'react-loading-skeleton/dist/skeleton.css';
import type { AppProps } from 'next/app';
import Web3Provider from '@/components/Web3Provider';
import { Provider } from 'react-redux';
import { WalletProvider } from '@/contexts/wallet-context';
import 'bootstrap/dist/css/bootstrap.min.css';
import ThemeProvider, { ThemedGlobalStyle } from '@/theme/theme';
import store from '@/state';
import { Toaster } from 'react-hot-toast';
import '@/styles/index.scss';
import ClientOnly from '@/components/Utils/ClientOnly';
import Web3ReactDetector from './Web3ReactDetector';
import { SkeletonTheme } from 'react-loading-skeleton';
import AppSpinner from '@/components/AppSpinner';
import { HeadComponent } from '@/metadata';
// import { HeadComponent } from '@/metadata';
import { MetaConfigInit } from '@/metadata/constants';
import { ChakraProvider } from '@chakra-ui/react';
import chakraThemes, { Fonts } from '@/theme/chakra-themes';

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <HeadComponent
        {...{
          ...MetaConfigInit,
          ...pageProps,
        }}
      />
      <ClientOnly>
        <Provider store={store}>
          <ChakraProvider theme={chakraThemes}>
            <Fonts />
            <ThemeProvider>
              <ThemedGlobalStyle />
              <Web3Provider>
                <Web3ReactDetector />
                <WalletProvider>
                  <SkeletonTheme baseColor="#0f0f0f" highlightColor="#898989">
                    <Component {...pageProps} />
                  </SkeletonTheme>
                  <Toaster
                    position="top-center"
                    toastOptions={{
                      style: {
                        lineBreak: 'auto',
                        maxWidth: 450,
                      },
                    }}
                    reverseOrder={false}
                  />
                  <AppSpinner />
                </WalletProvider>
              </Web3Provider>
            </ThemeProvider>
          </ChakraProvider>
        </Provider>
      </ClientOnly>
    </>
  );
};

export default App;
