import { web3ReactActionCreators } from '@/state/web3React/reducer';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const Web3ReactDetector = (): null => {
  const web3Data = useWeb3React();
  const dispatch = useDispatch();
  const { connector } = useWeb3React();

  // Connect Eagerly (Pre-step before connect to Web3)
  useEffect(() => {
    try {
      connector.connectEagerly && connector.connectEagerly();
    } catch (error: any) {
      console.log('[Web3ReactDetector] error ', error);
    }
  }, [connector]);

  useEffect(() => {
    dispatch(web3ReactActionCreators.setWeb3Data(web3Data));
  }, [web3Data]);

  return null;
};

export default Web3ReactDetector;
