import { createSlice } from '@reduxjs/toolkit';

export interface IAppState {
  appState: 'INIT' | 'READY';
}

export const initialState: IAppState = {
  appState: 'INIT',
};

const slice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setAppState(state, { payload }: { payload: 'INIT' | 'READY' }) {
      state.appState = payload;
    },
  },
});

export const { setAppState } = slice.actions;

export default slice.reducer;
