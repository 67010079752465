/* eslint-disable @typescript-eslint/no-non-null-assertion */

// App configs
export const APP_ENV: string = process.env.NEXT_PUBLIC_MODE!;
export const TC_BRIDGES_API_URL: string =
  process.env.NEXT_PUBLIC_API_TC_BRIDGES_URL!;

export const CDN_URL: string = process.env.NEXT_PUBLIC_CDN_URL!;

export const L2AAS_API_URL: string = process.env.NEXT_PUBLIC_L2AAS_API_URL!;
