import Head from 'next/head';
import { MetaConfig } from './type';

export const HeadComponent = (props: MetaConfig) => {
  const {
    tabTitle: title,
    tabDesc: description,
    thumbImgUrl: imageUrl,
    favIconUrl,
  } = props;
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="twitter:title" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <meta name="theme-color" content="#ffffff" />
      <link rel="icon" type="image/svg" sizes="16x16 32x32" href={favIconUrl} />
      <link rel="apple-touch-icon" sizes="16x16 32x32" href={favIconUrl} />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
    </Head>
  );
};
