import createLogger, { DEBUG } from '@/utils/logger';
import axios from 'axios';

const TIMEOUT = 5 * 60000;
const logger = createLogger('API');
const HEADERS = { 'Content-Type': 'application/json' };

const createAxiosInstance = ({
  baseURL = '',
  requireAuthorize = true,
}: {
  baseURL: string;
  requireAuthorize?: boolean;
}) => {
  const instance = axios.create({
    baseURL,
    timeout: TIMEOUT,
    headers: {
      ...HEADERS,
    },
  });

  instance.interceptors.request.use(
    (config) => {
      if (DEBUG) {
        logger('URL ==> ', `${config.baseURL}${config?.url}`);
        logger('METHOD ==> ', `${config.method?.toUpperCase()}`);
        logger('PAYLOAD ==> ', config.data);
        logger('FULL INFO ==> ', config);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (res) => {
      const result = res?.data?.data || res?.data?.result || res?.data;
      const error = res?.data?.error;
      if (error) {
        return Promise.reject(error);
      }
      return Promise.resolve(result);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      if (!error.response) {
        return Promise.reject(error);
      } else {
        const response = error?.response?.data || error;
        const errorMessage =
          response?.error ||
          error?.Message ||
          error?.message ||
          JSON.stringify(error);
        return Promise.reject(errorMessage);
      }
    },
  );

  return instance;
};

export default createAxiosInstance;
