import { Token } from './types';
import store from '@/state';

export const filterStatus = (tokenList: Token[]): Token[] => {
  return (
    tokenList.filter((token) => {
      if (token.status !== undefined && token.status === 0) {
        return false;
      }
      return true;
    }) || []
  );
};

export const filterNetwork = (tokenList: Token[]): Token[] => {
  const networkList = store.getState().networkReducer.networkList || [];
  const networkNameList = networkList.map((item) => item.networkName) || [];
  return tokenList.filter((token) => networkNameList.includes(token.network));
};

// ------------------------------------------------------
// Filter tokens before using throught app
// ------------------------------------------------------
export const filterManager = (tokenList: Token[]) => {
  tokenList = filterStatus(tokenList);
  return tokenList;
};
