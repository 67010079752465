import { TC_BRIDGES_API_URL } from '@/configs';
import { transformTokens } from '@/state/tokens/helper';
import { Token } from '@/state/tokens/types';
import createAxiosInstance from './http-client';

const apiClient = createAxiosInstance({ baseURL: TC_BRIDGES_API_URL });

const API_PATH = '/api/bridges-l2-tokens';

export const getTokenListAPI = async (): Promise<Token[]> => {
  try {
    const tokenList: any[] = await apiClient.get(`${API_PATH}`);

    //Transform some properties to type definition before
    // Ex: type => type = "NATIVE" | "BRC20" | "ERC20"
    const tokensList = transformTokens(tokenList);
    return tokensList;
  } catch (error: any) {
    console.log('[getTokenListAPI] error: ', error);
    throw error;
  }
};
