import { getConnection } from '@/connection';
import { useAppDispatch } from '@/state/hooks';
import {
  resetUser,
  updateEVMWallet,
  updateSelectedWallet,
} from '@/state/user/reducer';
import { getUserSelector } from '@/state/user/selector';
import { parseError } from '@utils/errorHelper';
import { useWeb3React } from '@web3-react/core';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

export interface IWalletContext {
  onDisconnect: () => Promise<void>;
  onConnect: () => Promise<string | null>;
  onConnectMetamask: () => Promise<string | null>;
}

const initialValue: IWalletContext = {
  onDisconnect: () => new Promise<void>((r) => r()),
  onConnect: () => new Promise<null>((r) => r(null)),
  onConnectMetamask: () => new Promise<null>((r) => r(null)),
};

export const WalletContext = React.createContext<IWalletContext>(initialValue);

export const WalletProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const { connector, provider } = useWeb3React();

  const dispatch = useAppDispatch();
  const user = useSelector(getUserSelector);

  const disconnect = React.useCallback(async () => {
    console.log('disconnecting...');
    console.log('user', user);
    if (connector && connector.deactivate) {
      await connector.deactivate();
    }
    await connector.resetState();
    dispatch(resetUser());
  }, [connector, dispatch, user]);

  const connect = React.useCallback(async () => {
    const connection = getConnection(connector);
    if (!connection) {
      throw new Error('Get connection error.');
    }
    await connection.connector.activate();
    const addresses = await connector.provider?.request({
      method: 'eth_accounts',
    });
    if (addresses && Array.isArray(addresses)) {
      const evmWalletAddress = addresses[0];
      dispatch(updateEVMWallet(evmWalletAddress));
      dispatch(updateSelectedWallet({ wallet: connection.type }));
      return evmWalletAddress;
    }
    return null;
  }, [dispatch, connector, provider]);

  useEffect(() => {
    const handleAccountsChanged = async () => {
      await disconnect();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };

    if (window.ethereum) {
      Object(window.ethereum).on('accountsChanged', handleAccountsChanged);
    }
  }, [disconnect]);

  const onConnectMetamask = async () => {
    if (connector) {
      try {
        const connection = getConnection(connector);
        if (!connection) {
          throw new Error('Get connection error.');
        }
        await connection.connector.activate();
      } catch (error) {
        toast.error(parseError(error));
      }
    }
    return null;
  };

  const contextValues = useMemo((): IWalletContext => {
    return {
      onDisconnect: disconnect,
      onConnect: connect,
      onConnectMetamask,
    };
  }, [disconnect, connect]);

  return (
    <WalletContext.Provider value={contextValues}>{children}</WalletContext.Provider>
  );
};
