import { WebsiteConfigInit } from '@/metadata/constants';
import { WebsiteConfig } from '@/metadata/type';
import { createSlice } from '@reduxjs/toolkit';

interface ConfigState {
  webisiteConfig: WebsiteConfig;
}

const initialState: ConfigState = {
  webisiteConfig: WebsiteConfigInit,
};

const routerSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setWebsiteConfig(state, { payload }) {
      state.webisiteConfig = payload;
    },
  },
});

export const { setWebsiteConfig } = routerSlice.actions;

export default routerSlice.reducer;
