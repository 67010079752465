'use client';

import { extendTheme, type ThemeConfig } from '@chakra-ui/react';

import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'TitilliumWeb';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('./fonts/TitilliumWeb.ttf') format('ttf'), url('./fonts/TitilliumWeb.ttf') format('ttf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'TitilliumWeb';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('./fonts/TitilliumWeb-SemiBold.ttf') format('ttf'), url('./fonts/TitilliumWeb-SemiBold.ttf') format('ttf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
  />
);

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const breakpoints = {
  base: '0px',
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
  '2xl': '1536px',
  max: '1600px',
};

const chakraThemes = extendTheme({
  config,
  breakpoints,
  styles: {
    global: {
      body: {
        minHeight: '100dvh',
        fontFamily: `TitilliumWeb`,
        fontWeight: 500,
        lineHeight: 'base',
        backgroundColor: '#000000',
      },
    },
  },
  textStyles: {
    primary: {
      fontFamily: 'TitilliumWeb',
    },
  },
});

export default chakraThemes;
